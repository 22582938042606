import React from 'react';
import { ReactComponent as Icon } from './svg/personOutline.svg';
import SvgIconComponent from './SvgIconComponent';

function PersonIcon() {
  return (
    <SvgIconComponent
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Icon />
    </SvgIconComponent>
  );
}

export default PersonIcon;
