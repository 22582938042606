import { Row, Space, Typography } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import { BoxColumn } from 'src/components/project/explore/summary/helpers';
import KpiRow from 'src/components/project/explore/summary/KpiRow';

export default function SpecsSummary({ compact }) {
  const [kpiFilters] = useConciergeContextState(['explore', 'filters', 'kpis']);

  const primaryKpis = kpiFilters
    ? Object.keys(kpiFilters).filter((code) => !kpiFilters[code].allow_null)
    : [];

  return (
    <ExplorePanel pad>
      <Typography.Text className="font-size-4" strong>
        Range Summary
      </Typography.Text>
      <Space direction="vertical" size={18}>
        <Row justify="end" gutter={[compact ? 8 : 16, 0]} wrap={false}>
          {!compact && (
            <BoxColumn width={110}>
              <Typography.Text strong>Rec.</Typography.Text>
              <Typography.Text type="secondary">/ Total</Typography.Text>
            </BoxColumn>
          )}
          <BoxColumn>
            <Typography.Text strong>In range</Typography.Text>
          </BoxColumn>
        </Row>
        {primaryKpis.map((code) => (
          <KpiRow compact={compact} key={code} kpiCode={code} />
        ))}
      </Space>
    </ExplorePanel>
  );
}
SpecsSummary.propTypes = {
  compact: PropTypes.bool
};
