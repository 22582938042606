import PropTypes from 'prop-types';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import numericRange from 'src/components/utils/numericRange';

export function MinMaxRangeString({ max, min, kpiUnit }) {
  return numericRange({
    min,
    max,
    rangeRender: (vMin, vMax) => `${vMin} - ${vMax} ${kpiUnit}`,
    noMaxRender: (v) => `${v} ${kpiUnit} or higher`,
    noMinRender: (v) => `${v} ${kpiUnit} or lower`,
    empty: ''
  });
}
MinMaxRangeString.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  kpiUnit: PropTypes.string
};

export function SpecMinMaxRangeString({ kpiCode }) {
  const [kpiFilter] = useConciergeContextState([
    'explore',
    'filters',
    'kpis',
    kpiCode
  ]);
  const { min, max, units } = kpiFilter || {};

  return numericRange({
    min,
    max,
    rangeRender: (vMin, vMax) => `${vMin}-${vMax} ${units}`,
    noMaxRender: (v) => `> ${v} ${units}`,
    noMinRender: (v) => `< ${v} ${units}`,
    empty: ''
  });
}
SpecMinMaxRangeString.propTypes = {
  kpiCode: PropTypes.string
};
