import { Radio, Skeleton } from 'antd';
import React from 'react';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import useMetaProperty from 'src/hooks/useMetaProperty';

export default function MaterialFilter() {
  const [materialFilter, setMaterialFilter] = useConciergeContextState([
    'explore',
    'filters',
    'type_code__in'
  ]);
  const property = useMetaProperty('type');

  if (!property) return <Skeleton />;

  return (
    <Radio.Group
      options={property.options.map((o) => ({
        label: o.value,
        value: o.code
      }))}
      value={materialFilter}
      onChange={({ target: { value } }) => setMaterialFilter(value)}
    />
  );
}
