import React, { createContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import CircularLogo from 'src/components/icons/CircularLogo';
import DashboardIcon from 'src/components/icons/Dashboard';
import IntercomIcon from 'src/components/icons/Intercom';
import ProjectIcon from 'src/components/icons/Project';
import SearchIcon from 'src/components/icons/Search';
import ExploreIcon from 'src/components/icons/Explore';
import ManageIcon from 'src/components/icons/Manage';
import ProcureIcon from 'src/components/icons/Procure';
import { Button, Divider, Avatar, Spin } from 'antd';
import {
  BuildFilled,
  LeftOutlined,
  LockFilled,
  MailOutlined,
  ProfileOutlined,
  RightOutlined
} from '@ant-design/icons';
import TruncatableLabel from 'src/components/utils/TruncatableLabel';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getProjectsForUser, getProjects } from 'src/Query';
import CircularLogoSymbol from 'src/components/icons/CircularLogoSymbol';
import ThunderboltIcon from 'src/components/icons/Thunderbolt';
import useSesameInvite from 'src/components/sesame/useSesameInvite';
import useIsConcierge from 'src/hooks/useIsConcierge';
import Lock from 'src/components/icons/Lock';
import { useAuthentication, useUser } from 'src/utils/authentication';
import useIsSupplier from 'src/hooks/useIsSupplier';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';

export const SidebarMenuContext = createContext();

function Menu({ selected, items, className }) {
  const [expanded, setExpanded] = useState([]);
  const expandCollapse = (index) => {
    const ex = expanded[index];
    const expandedNew = [...expanded];
    expandedNew[index] = !ex;
    setExpanded(expandedNew);
  };

  const menuClick = (item, index) => {
    if (!item.disabled) {
      if (item.subitems) {
        expandCollapse(index);
      } else {
        item.onClick(item.key);
      }
    }
  };
  return (
    <div className={`sidebar-menu ${className || ''}`}>
      {items.map((item, index) => (
        <React.Fragment key={item.key}>
          <div
            className={`sidebar-menu-item ${
              index === selected ? 'selected' : ''
            } ${item.disabled ? 'disabled' : ''} sidebar-menu-item-${item.key}`}
            onClick={() => menuClick(item, index)}
            role="presentation"
          >
            {item.icon}
            <TruncatableLabel text={item.name} className="name" />
            {item.disabled && <LockFilled />}
            {item.tag && <div className="tag">{item.tag}</div>}
            {item.subitems && (
              <Button type="bare" onClick={() => expandCollapse(index)}>
                {expanded[index] ? <LeftOutlined /> : <RightOutlined />}
              </Button>
            )}
          </div>
          {item.subitems && expanded[index] && (
            <div className="sidebar-submenu">
              {item.subitems.map((subitem, indexSub) => (
                <div
                  key={subitem.name}
                  className="sidebar-menu-subitem"
                  role="presentation"
                  onClick={() =>
                    item.disabled || item.onClick(item.key, subitem)
                  }
                >
                  {subitem.icon}
                  <TruncatableLabel text={subitem.name} className="subitem" />
                  {subitem.disabled && <LockFilled />}
                  {subitem.tag && <div className="tag">{subitem.tag}</div>}
                </div>
              ))}
              {item.isLoading && <Spin />}
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

Menu.propTypes = {
  items: PropTypes.array.isRequired,
  selected: PropTypes.number,
  className: PropTypes.string
};

const EMAIL_ADDRESS = 'ryan@circular.co';

function Sidebar() {
  const concierge = useIsConcierge();
  const supplier = useIsSupplier() || concierge;
  const { data: user } = useUser();

  const navigate = useNavigate();
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState(0);
  const [hide] = useConciergeContextState(['explore', 'hide']);

  const { signOut } = useAuthentication();

  const DEFAULT_PROJECTS = [];

  const {
    data: rfqProjects = DEFAULT_PROJECTS,
    isLoading: isLoadingRfqProjects
  } = useQuery({
    queryKey: ['projects-for-user'],
    queryFn: () => getProjectsForUser(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 10 * 60,
    cacheTime: 1000 * 15 * 60
  });

  const { data: projects = DEFAULT_PROJECTS, isLoading: isLoadingProjects } =
    useQuery({
      queryKey: ['projects', { all: true }],
      queryFn: () => getProjects({ all: true }),
      refetchOnWindowFocus: false,
      staleTime: 1000 * 10 * 60,
      cacheTime: 1000 * 15 * 60
    });

  const menuClick = (key, subitem) => {
    if (subitem?.onClick) subitem.onClick();
    else navigate(`/project/${key}${subitem?.key ? `/${subitem.key}` : ''}`);
  };

  const signOutClick = () => {
    signOut();
    navigate('/');
  };

  const rfqProjectMenuItems = useMemo(
    () =>
      rfqProjects.map((p) => ({
        key: p.request_for_quote,
        name: p.title || 'Project',
        icon: <ProjectIcon />,
        tag: p.status === 'demo' ? 'Demo' : null
      })),
    [rfqProjects]
  );

  const exploreProjects = useMemo(
    () => [
      ...(concierge
        ? [
            {
              key: `?`,
              name: 'Playground',
              icon: <ThunderboltIcon />
            }
          ]
        : []),
      ...(projects?.length
        ? projects
            .filter((p) => p.stage === 'explore')
            .map((p) => ({
              key: `?project=${p.uuid}`,
              name: p.name || 'Project',
              icon: <ProjectIcon />
            }))
            .sort((a, b) => a.name.localeCompare(b.name))
        : [])
    ],
    [projects]
  );

  const searchProjects = useMemo(
    () => [
      ...(concierge
        ? [
            {
              key: `?`,
              name: 'Playground',
              icon: <ThunderboltIcon />
            }
          ]
        : []),
      ...(projects?.length
        ? projects
            .filter((p) => p.stage === 'search')
            .map((p) => ({
              key: `?project=${p.uuid}`,
              name: p.name || 'Project',
              icon: <ProjectIcon />
            }))
            .sort((a, b) => a.name.localeCompare(b.name))
        : [])
    ],
    [projects]
  );

  const {
    openModal: openSesameInviteModal,
    ModalComponent: SesameInviteModal
  } = useSesameInvite();

  const adminMenuItems = [
    {
      key: 'sesameInvite',
      name: 'Magic Link Generator',
      onClick: openSesameInviteModal
    },
    {
      key: 'proposalBuilder',
      name: 'Proposal Builder',
      onClick: () => {
        navigate('/concierge/proposal-builder');
      }
    },
    {
      key: 'marketsPlayground',
      name: 'Markets Playground',
      onClick: () => {
        navigate('/concierge/markets-playground');
      }
    },
    {
      key: 'changeLog',
      name: 'Change Log',
      onClick: () => {
        window
          .open(
            'https://devops.circular.co/changelog/circular-exchange/circular-react/main',
            '_blank'
          )
          .focus();
      }
    },
    {
      key: 'version',
      name: `Version ${process.env.REACT_APP_GITHUB_SHA?.slice(0, 8) || '-'}`,
      onClick: () => {}
    }
  ];

  const menuItems = useMemo(
    () => [
      ...((!supplier || concierge) && !hide
        ? [
            {
              key: 'dashboard',
              name: 'Dashboard',
              icon: <DashboardIcon />,
              onClick: menuClick
            },
            {
              key: 'portfolio',
              name: 'Portfolio',
              icon: <BuildFilled />,
              onClick: menuClick
            },
            {
              key: 'explore',
              name: 'Explore',
              icon: <ExploreIcon />,
              subitems: exploreProjects,
              onClick: menuClick,
              isLoading: isLoadingProjects
            },
            {
              key: 'search',
              name: 'Search',
              icon: <SearchIcon />,
              subitems: searchProjects,
              onClick: menuClick,
              isLoading: isLoadingProjects
            },
            {
              key: 'procure',
              name: 'Procure',
              icon: <ProcureIcon />,
              subitems: rfqProjectMenuItems,
              onClick: menuClick,
              isLoading: isLoadingRfqProjects
            },
            {
              key: 'manage',
              name: 'Manage',
              icon: <ManageIcon />,
              subitems: rfqProjectMenuItems,
              onClick: menuClick,
              isLoading: isLoadingRfqProjects
            }
          ]
        : []),
      ...(supplier && !concierge
        ? [
            {
              key: 'profile',
              name: 'Profile',
              icon: <ProfileOutlined />,
              onClick: () => {
                navigate(`/supplier/${user?.company?.uuid}`);
              }
            },
            {
              key: 'supplier-insights',
              name: 'Insights',
              icon: <ManageIcon />,
              onClick: () => {
                navigate(`/supplier/insights`);
              }
            }
          ]
        : []),
      ...(concierge
        ? [
            {
              key: 'profile',
              name: 'Profile',
              icon: <ProfileOutlined />,
              onClick: () => {
                navigate(`/supplier/${user?.company?.uuid}`);
              }
            },
            {
              key: 'supplier-insights',
              name: 'Insights',
              icon: <ManageIcon />,
              onClick: () => {
                navigate(`/supplier/insights`);
              }
            }
          ]
        : []),
      {
        key: 'signOut',
        name: 'Sign out',
        icon: <Lock />,
        onClick: signOutClick
      },
      ...(concierge
        ? [
            {
              key: 'admin',
              name: 'Admin',
              icon: (
                <>
                  <ThunderboltIcon />
                  {SesameInviteModal}
                </>
              ),
              subitems: adminMenuItems,
              onClick: menuClick
            }
          ]
        : [])
    ],
    [rfqProjectMenuItems, adminMenuItems, exploreProjects, searchProjects]
  );

  useEffect(() => {
    const matches = location.pathname.match(
      /^\/(project|supplier)\/(.+?)\/?$/i
    );

    if (matches) {
      const type = matches[1]; // Either "project" or "supplier"
      const page = matches[2];

      const uuidMatch = page.match(
        /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
      );

      if (uuidMatch) {
        const uuid = uuidMatch[0];
        if (type === 'project') {
          // Project details page
          setSelectedMenu(undefined);
        } else if (uuid === user?.company.uuid) {
          // Viewing own supplier profile
          const index = menuItems.findIndex((item) => item.key === 'profile');
          setSelectedMenu(index);
        } else {
          // Viewing some other company's profile
          const index = menuItems.findIndex((item) => item.key === 'search');
          setSelectedMenu(index);
        }
      } else if (type === 'project') {
        // dashboard / insights page
        const index = menuItems.findIndex((item) => item.key === page);
        setSelectedMenu(index);
      } else {
        // Supplier insights page
        const index = menuItems.findIndex(
          (item) => item.key === `supplier-${page}`
        );
        setSelectedMenu(index);
      }
    }
  }, [location, rfqProjectMenuItems, menuItems, user]);

  const contactMethods = {
    email: {
      label: 'Send email',
      action: () => {
        window.location = `mailto:${EMAIL_ADDRESS}`;
      },
      icon: <MailOutlined />
    },
    chat: {
      label: 'Start chat',
      action: () => {
        window.Intercom('show');
      },
      icon: <IntercomIcon />
    }
  };
  const contactMethod =
    typeof window.Intercom === 'function'
      ? contactMethods.chat
      : contactMethods.email;

  return (
    <div
      className="project-sidebar"
      style={{ background: process.env.REACT_APP_BRAND_BACKGROUND_COLOR }}
    >
      <div className="sidebar-header">
        <CircularLogoSymbol />
        <CircularLogo />
      </div>
      <div className="sidebar-body">
        <Menu selected={selectedMenu} items={menuItems} />
      </div>
      <Divider />
      <div className="sidebar-footer">
        <div className="cs-card">
          <div className="cs-rep">
            <Avatar alt="User avatar" src={user?.avatar}>
              {user?.name.slice(0, 1)}
            </Avatar>
            <div className="cs-name">
              {user?.name}
              <span>{user?.email}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
