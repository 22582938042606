import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Col, Divider, Row, Select } from 'antd';

import ExploreSection from 'src/components/project/explore/ExploreSection';
import SpecSpiderChart, {
  mostUsedKpisFromAnalytics
} from 'src/components/project/explore/charts/SpecSpiderChart';
import SpecCapabilityHistogram from 'src/components/project/explore/charts/SpecCapabilityHistogram';
import SpecSupplierCapabilityBarChart from 'src/components/project/explore/charts/SpecSupplierCapabilityBarChart';
import { KpiName } from 'src/components/project/explore/filters/KpiFilter';
import useHistogramSettings from 'src/components/project/explore/hooks/useHistogramSettings';
import HeatmapFromFilters from 'src/components/viz/HeatmapFromFilters';
import { getKpiAnalytics } from 'src/Query/warehouse';
import useDebouncedQuery from 'src/hooks/useDebouncedQuery';
import SpecsSummary from 'src/components/project/explore/summary/SpecsSummary';

import ExplorePanel from './ExplorePanel';

export default function Specifications({
  kpiTabs,
  filters,
  showSummary = true
}) {
  const { data: kpiData } = useDebouncedQuery({
    queryKey: ['warehouse', 'kpi-analytics', filters],
    queryFn: () => getKpiAnalytics(filters)
  });

  const kpiTabCodes = useMemo(() => Object.keys(kpiTabs || {}), [kpiTabs]);

  const tabsKpiCodes = useMemo(() => {
    if (kpiTabCodes.length) return kpiTabCodes;
    if (kpiData)
      return [
        ...kpiTabCodes,
        ...mostUsedKpisFromAnalytics(kpiData, kpiTabCodes).slice(
          0,
          5 - Math.min(kpiTabCodes.length, 5)
        )
      ];
    return [];
  }, [kpiData, kpiTabCodes]);

  const [tab, setTab] = useState('summary');
  const tabItems = useMemo(
    () =>
      (showSummary
        ? [
            {
              key: 'summary',
              label: 'Summary',
              children: <SummaryTab />
            }
          ]
        : []
      ).concat(
        tabsKpiCodes.map((filterKpi) => ({
          key: filterKpi.toLocaleLowerCase(),
          label: <KpiName codeOrUuid={filterKpi} />,
          children: (
            <SpecTab
              filters={filters}
              kpiCode={filterKpi}
              kpiFilters={kpiTabs}
            />
          )
        }))
      ),
    [filters, kpiTabs, showSummary, tabsKpiCodes]
  );

  return (
    <>
      <ExploreSection title="Specification Comparison">
        <ExplorePanel pad={16}>
          <HeatmapFromFilters />
        </ExplorePanel>
      </ExploreSection>
      <Divider />
      <ExploreSection title="Specification Analysis">
        <Select
          className="mb-md"
          style={{ width: 330 }}
          value={tab}
          onChange={setTab}
          options={tabItems.map(({ key, label }) => ({
            value: key,
            label
          }))}
        />
        {tabItems.find(({ key }) => key === tab)?.children}
      </ExploreSection>
      {/* <Tabs items={tabItems} /> */}
    </>
  );
}

Specifications.propTypes = {
  filters: PropTypes.object,
  kpiTabs: PropTypes.object,
  showSummary: PropTypes.bool
};

function SummaryTab() {
  return (
    <Row gutter={[16, 0]} align="stretch">
      <Col span={8}>
        <SpecSpiderChart />
      </Col>
      <Col span={16}>
        <SpecsSummary compact />
      </Col>
    </Row>
  );
}

function SpecTab({ filters, kpiCode, kpiFilters }) {
  const { data: kpiData } = useDebouncedQuery({
    queryKey: ['warehouse', 'kpi-analytics', filters],
    queryFn: () => getKpiAnalytics(filters)
  });

  const kpiValues = kpiData?.[kpiCode]?.values || [];

  const {
    decimalPrecision,
    min: normalizedMin,
    max: normalizedMax,
    step: bucketSize
  } = useHistogramSettings(
    kpiValues,
    kpiFilters?.[kpiCode]?.min,
    kpiFilters?.[kpiCode]?.max
  );

  return (
    <Row gutter={[16, 0]}>
      <Col span={12}>
        <SpecCapabilityHistogram
          decimalPrecision={decimalPrecision}
          filters={filters}
          kpiCode={kpiCode}
          kpiFilters={kpiFilters}
          max={normalizedMax}
          min={normalizedMin}
          step={bucketSize}
          excludeMyData
        />
      </Col>
      <Col span={12} style={{ position: 'relative' }}>
        <SpecSupplierCapabilityBarChart
          decimalPrecision={decimalPrecision}
          filters={filters}
          kpiCode={kpiCode}
          kpiFilters={kpiFilters}
          max={normalizedMax}
          min={normalizedMin}
          step={bucketSize}
          excludeMyData
        />
      </Col>
    </Row>
  );
}

SpecTab.propTypes = {
  filters: PropTypes.object,
  kpiCode: PropTypes.string,
  kpiFilters: PropTypes.object
};

function FilteredHeatmapTab() {
  return (
    <ExplorePanel
      pad
      style={{
        minHeight: 420,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <HeatmapFromFilters />
    </ExplorePanel>
  );
}
