import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import useIsConcierge from 'src/hooks/useIsConcierge';

export const ConciergeContext = createContext();

const defaultValue = {
  supplier: {
    logos: 'show'
  },
  buyer: {
    logos: 'show'
  },
  explore: {
    filters: { kpi_operator: 'or' },
    hide: false
  },
  proposal: {
    editable: false
  }
};

export default function ConciergeContextProvider({ children }) {
  const [values, setValues] = useState(defaultValue);
  const isConcierge = useIsConcierge();
  // FIXME we need to move this to a permissions handling function
  useEffect(() => {
    let v = values;
    v = updateObject(['explore', 'hide'], !isConcierge, v);
    v = updateObject(['proposal', 'editable'], isConcierge, v);
    setValues(v);
  }, [isConcierge]);

  const ctxValueObj = useMemo(
    () => ({
      values,
      setValues,
      patchValues: (key, value) => {
        const updatedValues = updateObject(key, value, values);

        setValues(updatedValues);
      },
      clearValues: (key) => {
        const updatedValues = updateObject(key, null, values, true);

        setValues(updatedValues);
      }
    }),
    [values, setValues]
  );

  return (
    <ConciergeContext.Provider value={ctxValueObj}>
      {children}
    </ConciergeContext.Provider>
  );
}
ConciergeContextProvider.propTypes = {
  children: PropTypes.node
};

export function useConciergeContext(keyOrKeys) {
  const { values: allValues } = useContext(ConciergeContext);

  const keys = !Array.isArray(keyOrKeys) ? [keyOrKeys] : keyOrKeys;

  let value = allValues;

  keys.forEach((k) => {
    if (value?.[k]) value = value[k];
    else value = null;
  });

  return value;
}

export function useConciergeContextState(key) {
  const { values, patchValues, clearValues } = useContext(ConciergeContext);

  const setFunc = (newValueOrFunction, callback = null) => {
    const newFiltersVal =
      typeof newValueOrFunction === 'function'
        ? newValueOrFunction(getObjectNestedKey(key, values))
        : newValueOrFunction;

    patchValues(key, newFiltersVal);

    if (callback) {
      callback();
    }
  };

  const clearFunc = (callback = null) => {
    clearValues(key);

    if (callback) {
      callback();
    }
  };

  return [getObjectNestedKey(key, values), setFunc, clearFunc];
}

function updateObject(keyOrKeys, newVal, object, remove = false) {
  const keys = arrayify(keyOrKeys);

  const keyName = keys[0];
  const isLastKey = keys.length === 1;

  const results = { ...object };

  if (isLastKey) {
    if (remove) {
      delete results[keyName];
      return results;
    }
    results[keyName] = newVal;
    return results;
  }

  results[keyName] = updateObject(
    keys.slice(1),
    newVal,
    results[keyName] || {},
    remove
  );

  return results;
}

function getObjectNestedKey(keyOrKeys, object) {
  const keys = arrayify(keyOrKeys);

  let value = object || {};

  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];

    if (!(key in value)) {
      value = null;
      break;
    }

    value = value[key];
  }

  return value;
}

function arrayify(key) {
  return !Array.isArray(key) ? [key] : key;
}
