import { Button, Col, Collapse, Row, Tag, Checkbox } from 'antd';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import FormFilter from 'src/components/project/explore/filters/FormFilter';
import KpiFilter, {
  SingleKpiFilter
} from 'src/components/project/explore/filters/KpiFilter';
import MaterialFilter from 'src/components/project/explore/filters/MaterialFilter';
import RegionFilter from 'src/components/project/explore/filters/RegionFilter';
import CapacityFilter from 'src/components/project/explore/filters/CapacityFilter';
import PropertyFilter from 'src/components/project/explore/filters/PropertyFilter';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import { filterOptionByRelationship } from 'src/utils/properties';
import IsCapabilityFilter from 'src/components/project/explore/filters/IsCapabilityFilter';
import DisableableContent from 'src/components/utils/DisableableContent';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import { CheckCircleFilled } from '@ant-design/icons';
import { MetaPropertyName } from 'src/hooks/useMetaProperty';
import { prettyNumberRound } from 'src/components/utils/prettyNumber';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';
import CountryFilter from './explore/filters/CountryFilter';

export const primaryKpisByMaterial = {
  rpet: ['IV', 'D'],
  rpp: ['MFI', 'D'],
  rhdpe: ['MFI', 'D'],
  rldpe_rlldpe: ['MFI', 'D']
};

export default function FiltersSidebar() {
  return (
    <div className="filters-sidebar">
      <Filters />
    </div>
  );
}

const DEFAULT_FILTERS = {
  kpi_operator: 'or'
};

function Filters() {
  const [filters, setFilters] = useConciergeContextState([
    'explore',
    'filters'
  ]);

  const [form] = useConciergeContextState([
    'explore',
    'filters',
    'form_code__in'
  ]);
  const balesMode = form === 'bales' || form === 'regrind_flakes';

  const setKpiOperator = (active) => {
    setFilters({
      ...(filters || {}),
      kpi_operator: active ? 'or' : 'and'
    });
  };

  const [materialType] = useConciergeContextState([
    'explore',
    'filters',
    'type_code__in'
  ]);
  // Currently we only filter options for bale_grade property
  const filterByMaterial = useMemo(
    () => filterOptionByRelationship([materialType]),
    [materialType]
  );
  const filterByForm = useMemo(
    () => filterOptionByRelationship([form]),
    [form]
  );

  // Returns an array of kpi codes
  const primaryKpis = useMemo(() => {
    if (!materialType) return [];

    return primaryKpisByMaterial[materialType] || [];
  }, [materialType, primaryKpisByMaterial]);

  const relevantFilters = useRelevantFiltersForForm();

  const step1Done = useMemo(
    () =>
      balesMode
        ? !!relevantFilters.type_code__in &&
          !!relevantFilters.form_code__in &&
          !!(
            relevantFilters.region_code__in || relevantFilters.country_code__in
          )
        : !!relevantFilters.type_code__in &&
          !!relevantFilters.form_code__in &&
          relevantFilters.kpis &&
          Object.keys(relevantFilters.kpis).length >= primaryKpis.length,
    [relevantFilters, primaryKpis]
  );
  const step2Done = useMemo(
    () =>
      balesMode
        ? step1Done &&
          !!relevantFilters.properties &&
          Object.keys(relevantFilters.properties).length
        : step1Done &&
          relevantFilters.kpis &&
          Object.keys(relevantFilters.kpis).length > primaryKpis.length,
    [relevantFilters, primaryKpis]
  );
  const step3Done = useMemo(
    () =>
      step2Done &&
      !!relevantFilters.properties &&
      Object.keys(relevantFilters.properties).length,
    [relevantFilters]
  );

  return (
    <>
      <FormFilter />

      <StepsProgress
        stepsStatus={
          balesMode ? [step1Done, step2Done] : [step1Done, step2Done, step3Done]
        }
        right={
          <Button
            style={{
              fontWeight: 300,
              padding: '0 8px'
            }}
            onClick={() => setFilters(DEFAULT_FILTERS)}
            htmlType="button"
          >
            Reset
          </Button>
        }
      />
      <ExplorePanel pad={12}>
        <Row className="mb-sm" align="middle">
          <Col style={{ lineHeight: 0 }}>
            <StepStatus done={step1Done} />
          </Col>
          <Col>
            <h3 className="mt-0">Step 1: Add Base Data</h3>
          </Col>
        </Row>
        <Collapse
          className="filters-box"
          defaultActiveKey={['primary_specs']}
          size="small"
          items={[
            {
              key: 'material',
              label: 'Material',
              extra: materialType && (
                <Tag color="blue">
                  <MetaPropertyName
                    property="type"
                    optionValue={materialType}
                  />
                </Tag>
              ),
              children: <MaterialFilter />
            },
            {
              key: 'capacity',
              label: 'Quantity',
              extra: filters?.capacity__gte && (
                <Tag color="blue">
                  &gt; {prettyNumberRound(filters?.capacity__gte)} lb
                </Tag>
              ),
              children: <CapacityFilter />
            },
            {
              key: 'region',
              label: 'Region',
              extra: filters?.region_code__in?.length && (
                <Tag color="blue">{filters.region_code__in.length}</Tag>
              ),
              children: <RegionFilter />
            },
            {
              key: 'country',
              label: 'Country',
              extra: filters?.country_code__in?.length && (
                <Tag color="blue">{filters.country_code__in.length}</Tag>
              ),
              children: <CountryFilter />
            },
            ...(!balesMode
              ? [
                  {
                    key: 'primary_specs',
                    label: 'Primary Specifications',
                    extra: primaryKpis?.length && (
                      <Tag color="blue">{primaryKpis.join(', ')}</Tag>
                    ),
                    children: (
                      <div className="kpi-filter">
                        {primaryKpis.map((kpiCode) => (
                          <SingleKpiFilter
                            key={kpiCode}
                            kpiCode={kpiCode}
                            isPrimary
                          />
                        ))}
                      </div>
                    ),
                    className: 'ghost'
                  }
                ]
              : [])
          ]}
        />
      </ExplorePanel>
      <DisableableContent disabled={!step1Done}>
        <ExplorePanel className="mt-lg" pad={12}>
          {balesMode ? (
            <>
              <Row className="mb-sm" align="middle">
                <Col style={{ lineHeight: 0 }}>
                  <StepStatus done={step2Done} />
                </Col>
                <Col>
                  <h3 className="mt-0">Step 2: Quality</h3>
                </Col>
              </Row>
              <Collapse
                className="filters-box"
                size="small"
                items={[
                  {
                    key: 'bale_source',
                    label: 'Source',
                    extra: filters?.properties?.bale_source?.length && (
                      <Tag color="blue">
                        {filters.properties.bale_source.length}
                      </Tag>
                    ),
                    children: <PropertyFilter propertyCode="bale_source" />
                  },
                  {
                    key: 'bale_form',
                    label: 'Form',
                    extra: filters?.properties?.bale_form?.length && (
                      <Tag color="blue">
                        {filters.properties.bale_form.length}
                      </Tag>
                    ),
                    children: <PropertyFilter propertyCode="bale_form" />
                  },
                  {
                    key: 'bale_grade',
                    label: 'Contamination',
                    extra: filters?.properties?.bale_grade?.length && (
                      <Tag color="blue">
                        {filters.properties.bale_grade.length}
                      </Tag>
                    ),
                    children: <PropertyFilter propertyCode="bale_grade" />
                  },
                  {
                    key: 'color',
                    label: 'Color',
                    extra: filters?.properties?.color?.length && (
                      <Tag color="blue">{filters.properties.color.length}</Tag>
                    ),
                    children: (
                      <PropertyFilter
                        filterOptions={materialType ? filterByForm : null}
                        propertyCode="color"
                      />
                    )
                  },
                  {
                    key: 'verified',
                    label: 'Verified Suppliers',
                    extra: filters?.is_capability === false && (
                      <Tag color="blue">Yes</Tag>
                    ),
                    children: <IsCapabilityFilter />
                  }
                ]}
              />
            </>
          ) : (
            <>
              <KpiFilter
                title={
                  <Row align="middle">
                    <Col style={{ lineHeight: 0 }}>
                      <StepStatus done={step2Done} />
                    </Col>
                    <Col>
                      <h3>Step 2: Secondary Specs</h3>
                    </Col>
                  </Row>
                }
                excludeKpis={primaryKpis}
              />
              <div className="mt-lg">
                <Checkbox
                  checked={filters?.kpi_operator === 'or'}
                  onChange={(e) => setKpiOperator(e.target.checked)}
                />
                <span className="setting-switch-label">
                  Match any specification
                </span>
              </div>
            </>
          )}
        </ExplorePanel>
      </DisableableContent>
      {!balesMode && (
        <DisableableContent disabled={!step2Done}>
          <ExplorePanel className="mt-lg" pad={12}>
            <Row className="mb-sm" align="middle">
              <Col style={{ lineHeight: 0 }}>
                <StepStatus done={step3Done} />
              </Col>
              <Col>
                <h3 className="m-0">Step 3: Advanced Filters</h3>
              </Col>
            </Row>
            <Collapse
              className="filters-box"
              size="small"
              items={[
                /* eslint-disable dot-notation */
                {
                  key: 'grade',
                  label: 'Grade',
                  extra: filters?.properties?.grade?.length && (
                    <Tag color="blue">{filters.properties.grade.length}</Tag>
                  ),
                  children: <PropertyFilter propertyCode="grade" />
                },
                {
                  key: 'processing-method',
                  label: 'Processing Method',
                  extra: filters?.properties?.['processing-method']?.length && (
                    <Tag color="blue">
                      {filters.properties['processing-method'].length}
                    </Tag>
                  ),
                  children: <PropertyFilter propertyCode="processing-method" />
                },
                {
                  key: 'application',
                  label: 'Application',
                  extra: filters?.properties?.['application']?.length && (
                    <Tag color="blue">
                      {filters.properties['application'].length}
                    </Tag>
                  ),
                  children: <PropertyFilter propertyCode="application" />
                },
                {
                  key: 'color',
                  label: 'Color',
                  extra: filters?.properties?.['color']?.length && (
                    <Tag color="blue">{filters.properties['color'].length}</Tag>
                  ),
                  children: <PropertyFilter propertyCode="color" />
                },
                {
                  key: 'source-type',
                  label: 'Source Type',
                  extra: filters?.properties?.['source-type']?.length && (
                    <Tag color="blue">
                      {filters.properties['source-type'].length}
                    </Tag>
                  ),
                  children: <PropertyFilter propertyCode="source-type" />
                },
                {
                  key: 'additive',
                  label: 'Additives / Fillers',
                  extra: filters?.properties?.['additive']?.length && (
                    <Tag color="blue">
                      {filters.properties['additive'].length}
                    </Tag>
                  ),
                  children: <PropertyFilter propertyCode="additive" />
                },
                {
                  key: 'polymer-type',
                  label: 'Polymer Type',
                  extra: filters?.properties?.['polymer-type']?.length && (
                    <Tag color="blue">
                      {filters.properties['polymer-type'].length}
                    </Tag>
                  ),
                  children: <PropertyFilter propertyCode="polymer-type" />
                },
                {
                  key: 'industry',
                  label: 'Industry',
                  extra: filters?.properties?.['industry']?.length && (
                    <Tag color="blue">
                      {filters.properties['industry'].length}
                    </Tag>
                  ),
                  children: <PropertyFilter propertyCode="industry" />
                },
                {
                  key: 'certs',
                  label: 'Certifications / Standards',
                  extra: filters?.properties?.['certs']?.length && (
                    <Tag color="blue">{filters.properties['certs'].length}</Tag>
                  ),
                  children: (
                    <PropertyFilter
                      filterOptions={(opt) =>
                        ![
                          'efsa',
                          'philippines_fda',
                          'indonesia_fda',
                          'fda_lno',
                          'fda'
                        ].includes(opt.code)
                      }
                      propertyCode="certs"
                    />
                  )
                },
                {
                  key: 'flammability',
                  label: 'Flammability Rating',
                  extra: filters?.properties?.['flammability']?.length && (
                    <Tag color="blue">
                      {filters.properties['flammability'].length}
                    </Tag>
                  ),
                  children: <PropertyFilter propertyCode="flammability" />
                }
                /* eslint-enable dot-notation */
              ]}
            />
          </ExplorePanel>
        </DisableableContent>
      )}
    </>
  );
}

function StepStatus({ done }) {
  if (!done)
    return (
      <div
        style={{
          width: 16,
          height: 16,
          border: '1px solid #DFE3ED',
          borderRadius: 8,
          display: 'inline-block',
          marginRight: 8
        }}
      />
    );

  return (
    <CheckCircleFilled
      style={{ fontSize: 20, color: '#389E0D', marginRight: 8 }}
    />
  );
}
StepStatus.propTypes = {
  done: PropTypes.bool
};

function StepsProgress({ stepsStatus, right }) {
  return (
    <div
      className="p-sm mb-md box-panel solid"
      style={{
        background: '#F9F0FF'
      }}
    >
      <Row className="mb-sm" justify="space-between">
        <Col>
          <h3>
            {stepsStatus.every((done) => done)
              ? 'Ready to Add Suppliers!'
              : 'Configure Your Search'}
          </h3>
        </Col>
        <Col>{right}</Col>
      </Row>
      <Row wrap={false} style={{ borderRadius: 2, overflow: 'hidden' }}>
        {stepsStatus.map((done, i) => (
          <Col
            key={i}
            style={{
              height: 6,
              flex: '1',
              background: done ? '#722ED1' : '#D3ADF7',
              marginRight: i + 1 === stepsStatus.length ? 0 : 3
            }}
          />
        ))}
      </Row>
    </div>
  );
}
StepsProgress.propTypes = {
  stepsStatus: PropTypes.arrayOf(PropTypes.bool),
  right: PropTypes.node
};
