import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import ExploreSection from 'src/components/project/explore/ExploreSection';
import { getTdsListBySupplier } from 'src/Query/warehouse';
import DocumentIcon from 'src/components/icons/Document';
import { Button, Col, Pagination, Row, Typography } from 'antd';
import PersonIcon from 'src/components/icons/Person';
import PlayListIcon from 'src/components/icons/PlayListAdd';
import prettyNumber from 'src/components/utils/prettyNumber';
import VisibilityIcon from 'src/components/icons/Visibility';
import useLoadingStateClass from 'src/hooks/useLoadingStateClass';

const EMPTY_OPTIONS = {};

export default function Suppliers({ expandedFilter }) {
  const [page, setPage] = useState(1);
  const [filters = EMPTY_OPTIONS] = useConciergeContextState([
    'explore',
    'filters'
  ]);
  const pagedFilters = useMemo(
    () => ({ ...filters, page, expanded: expandedFilter }),
    [filters, page]
  );
  const { data: suppliers, isLoading: isLoadingSuppliers } = useQuery(
    ['warehouse', 'tds-supplier-list', pagedFilters],
    () => getTdsListBySupplier(pagedFilters)
  );
  const loadingStateClass = useLoadingStateClass(isLoadingSuppliers);

  const pageChange = (p) => {
    setPage(p);
  };
  // FIXME show isLoading state
  return (
    <ExploreSection className={loadingStateClass}>
      <div className="explore-suppliers-table">
        {(suppliers?.results || []).map((supplier) => (
          <SupplierRow key={supplier.company_id} supplier={supplier} />
        ))}
      </div>
      <Pagination
        current={page}
        onChange={pageChange}
        showSizeChanger={false}
        total={suppliers?.count || 0}
      />
      ;
    </ExploreSection>
  );
}

Suppliers.propTypes = {
  expandedFilter: PropTypes.object
};

function Tag({ text, highlight }) {
  return (
    <div
      className={`explore-suppliers-table--tag ${highlight ? 'highlight' : ''}`}
    >
      {text}
    </div>
  );
}

Tag.propTypes = {
  text: PropTypes.string,
  highlight: PropTypes.bool
};

function Count({ count }) {
  return (
    <div className="explore-suppliers-table--count">
      <DocumentIcon />
      {count}
    </div>
  );
}

Count.propTypes = {
  count: PropTypes.number
};

export function TdsRowHeader({ tdsList }) {
  const capacityUnits = tdsList[0]?.capacity_units || 'lb';
  return (
    <div>
      <Row gutter={8} className="explore-suppliers-table--row--tds-headers">
        <Col span={10}>
          <Typography.Text className="heavy sm">
            Matching Records
          </Typography.Text>
        </Col>
        <Col span={4}>
          <Typography.Text className="sm">Material</Typography.Text>
        </Col>
        <Col span={4}>
          <Typography.Text className="sm">Form</Typography.Text>
        </Col>
        <Col span={3}>
          <Typography.Text className="sm">Certifications</Typography.Text>
        </Col>
        <Col span={3}>
          <Typography.Text className="sm">
            Capacity&nbsp;({capacityUnits})
          </Typography.Text>
        </Col>
      </Row>
    </div>
  );
}

TdsRowHeader.propTypes = {
  tdsList: PropTypes.array
};

export function TdsRow({ tds }) {
  const id = tds.sku || tds.tds_id.slice(0, 8);
  const capacity = tds.capacity ? prettyNumber(tds.capacity) : undefined;
  return (
    <div className="explore-suppliers-table--row--tds-content">
      <Row gutter={8}>
        <Col span={10}>
          <Button size="small">
            <VisibilityIcon />
          </Button>
          &nbsp;&nbsp;
          <Typography.Text className="heavy sm">{id}</Typography.Text>
        </Col>
        <Col span={4}>
          <Typography.Text className="heavy sm">{tds.type}</Typography.Text>
        </Col>
        <Col span={4}>
          <Typography.Text className="heavy sm">{tds.form}</Typography.Text>
        </Col>
        <Col span={3}>
          <Typography.Text className="heavy sm"></Typography.Text>
        </Col>
        <Col span={3}>
          <Typography.Text className="heavy sm">{capacity}</Typography.Text>
        </Col>
      </Row>
    </div>
  );
}

TdsRow.propTypes = {
  tds: PropTypes.object
};

function formatLocation(l) {
  return [l.city, l.country === 'United States' && l.state, l.country]
    .filter((a) => a)
    .join(', ');
}

export function SupplierRow({ supplier }) {
  const [expanded, setExpanded] = useState(false);
  const types = useMemo(
    () => Array.from(new Set(supplier?.tds_list.map((t) => t.type))).join(', '),
    [supplier]
  );
  const forms = useMemo(
    () => Array.from(new Set(supplier?.tds_list.map((t) => t.form))).join(', '),
    [supplier]
  );
  const regions = supplier.region.join(', ');
  const locations = supplier.locations.map((l) => formatLocation(l)).join('; ');
  return (
    <div
      className={`explore-suppliers-table--panel ${expanded ? 'expanded' : ''}`}
    >
      <div
        role="presentation"
        className={`explore-suppliers-table--row ${expanded ? 'expanded' : ''}`}
        onClick={() => setExpanded(!expanded)}
      >
        <div className="explore-suppliers-table--row--top">
          {supplier?.distance === 0 ? (
            <Tag text="Verified" highlight />
          ) : (
            <Tag text="Potential" />
          )}
          <Count count={supplier?.tds_list?.length} />
          <div style={{ flexGrow: 1 }}></div>
          <Button size="small">
            <PersonIcon />
          </Button>
          <Button size="small" type="primary">
            <PlayListIcon /> &nbsp;Add to RFQ List
          </Button>
        </div>
        <Row gutter={8} className="explore-suppliers-table--row--content">
          <Col span={8}>
            <div className="explore-suppliers-table--row--title">
              {supplier.company_name}
            </div>
            <div>{locations}</div>
          </Col>
          <Col span={4}>
            <div className="explore-suppliers-table--row--header">{types}</div>
            <div>{forms}</div>
          </Col>
          <Col span={4}>
            <div className="explore-suppliers-table--row--header">
              {regions}
            </div>
          </Col>
        </Row>
      </div>
      <div hidden={!expanded} className="explore-suppliers-table--tds-list">
        <TdsRowHeader tdsList={supplier.tds_list} />
        {supplier.tds_list.map((t) => (
          <TdsRow key={t.tds_id} tds={t} />
        ))}
      </div>
    </div>
  );
}

SupplierRow.propTypes = {
  supplier: PropTypes.object
};
