import { Empty, Skeleton, Tabs, Typography } from 'antd';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { getSupplierCapabilities, getTDSs } from 'src/Query/warehouse';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import Location from 'src/components/project/explore/Location';
import Pricing from 'src/components/project/explore/Pricing';
import Specifications from 'src/components/project/explore/Specifications';
import emptyExplore from 'src/images/exploreEmpty.png';
import { prettyNumberRound } from 'src/components/utils/prettyNumber';
import useDebouncedQuery from 'src/hooks/useDebouncedQuery';
import BalesExplore from 'src/components/project/explore/BalesExplore';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';
import { primaryKpisByMaterial } from 'src/components/project/FiltersSidebar';
import { SpecMinMaxRangeString } from 'src/components/project/explore/MinMaxRangeString';
import { useLatestRelevantPriceForExplore } from 'src/components/analytics/MarketPriceByFormLineChart';
import { currencyValuePrice } from 'src/utils/currency';

export function ConditionalContent() {
  const filters = useRelevantFiltersForForm();
  const noFilters = Object.keys(filters).length === 0;
  return noFilters ? <EmptyExplore /> : <Content />;
}

export default function Content() {
  const [activeKpiFilters] = useConciergeContextState([
    'explore',
    'filters',
    'kpis'
  ]);
  const filters = useRelevantFiltersForForm();
  const [form] = useConciergeContextState([
    'explore',
    'filters',
    'form_code__in'
  ]);
  const [materialType] = useConciergeContextState([
    'explore',
    'filters',
    'type_code__in'
  ]);

  // Returns an array of kpi codes
  const primaryKpis = useMemo(() => {
    if (!materialType) return [];

    return primaryKpisByMaterial[materialType] || [];
  }, [materialType, primaryKpisByMaterial]);

  const filterSupplier = { ...filters, limit: 1, group_by_company: 'true' };
  const {
    data: filteredSupplierRecords,
    isLoading: isLoadingFilteredSupplierRecords
  } = useDebouncedQuery(
    ['warehouse', 'supplier-capability', filterSupplier],
    () => getSupplierCapabilities(filterSupplier)
  );

  const { data: filteredRecords, isLoading: isLoadingFilteredRecords } =
    useDebouncedQuery({
      queryKey: ['warehouse', 'technical-data-sheet', filters],
      queryFn: () => getTDSs(filters),
      keepPreviousData: true
    });

  const { data: totalRecords, isLoading: isLoadingTotalRecords } =
    useDebouncedQuery({
      queryKey: ['warehouse', 'technical-data-sheet'],
      queryFn: () => getTDSs(),
      keepPreviousData: true
    });

  const latestPriceData = useLatestRelevantPriceForExplore();

  const isLoading = isLoadingFilteredRecords || isLoadingTotalRecords;

  if (isLoading && !filteredRecords && !totalRecords) return <Skeleton />;

  const noFilters = filteredRecords?.count === totalRecords?.count;

  if (noFilters) return <EmptyExplore />;

  if (form === 'bales') return <BalesExplore />;

  return (
    <Tabs
      className="bordered-tabs"
      items={[
        {
          key: 'specs',
          label: (
            <TabTitle
              title="Specifications"
              subtitle={primaryKpis.map((kpiCode, i) => (
                <React.Fragment key={kpiCode}>
                  {kpiCode} <SpecMinMaxRangeString kpiCode={kpiCode} />
                  {i < primaryKpis.length - 1 && ' • '}
                </React.Fragment>
              ))}
            />
          ),

          children: (
            <Specifications
              description="Filtered specification data is compared against our database of supplier records. View a summary of the comparison and where else you could source material at the desired specification. Investigate further by viewing detailed curves of our dataset and supplier ranges."
              kpiTabs={activeKpiFilters}
              filters={filters}
            />
          )
        },
        {
          key: 'suppliers',
          label: (
            <TabTitle
              title="Suppliers"
              subtitle={`${
                filteredSupplierRecords
                  ? prettyNumberRound(filteredSupplierRecords.count)
                  : '-'
              } Suppliers • ${
                filteredRecords ? prettyNumberRound(filteredRecords.count) : '-'
              } Records`}
            />
          ),
          children: <Location />
        },
        {
          key: 'market',
          label: (
            <TabTitle
              title="Market"
              subtitle={
                latestPriceData &&
                `${currencyValuePrice(
                  latestPriceData.price,
                  latestPriceData.currency,
                  latestPriceData.units
                )} (${latestPriceData.label})`
              }
            />
          ),
          children: <Pricing />
        }
      ]}
    />
  );
}

function EmptyExplore() {
  return (
    <Empty image={emptyExplore} description="">
      <Typography.Title level={2}>Explore what's possible</Typography.Title>
      <Typography.Paragraph>
        Leverage Circular's database of over 50,000 records to get a real
        picture of what is possible with PCR.
      </Typography.Paragraph>
    </Empty>
  );
}

function TabTitle({ title, subtitle }) {
  return (
    <>
      <span className="font-size-4">{title}</span>
      <br />
      <Typography.Text
        type="secondary"
        className="font-size-2"
        style={{ fontWeight: 400 }}
      >
        {subtitle}
      </Typography.Text>
    </>
  );
}
TabTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
};
