import React, { useCallback, useState } from 'react';

import ExploreSection from 'src/components/project/explore/ExploreSection';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import { useMapPageWarehouse } from 'src/pages/maps/MapPageWarehouse';
import Suppliers from './Suppliers';

const VisualizationOptions = {
  Records: 'r',
  Suppliers: 's'
};

export default function Location() {
  const [showBySuppliers, setShowBySuppliers] = useState(false);

  const { MapComponent } = useMapPageWarehouse({
    useMapProps: { theme: 'light_new' },
    className: 'explore-map'
  });

  const chartTypeHandler = useCallback(
    (val) => {
      setShowBySuppliers(val.target.value === VisualizationOptions.Suppliers);
    },
    [setShowBySuppliers]
  );

  return (
    <>
      <ExplorePanel className="mb-lg" style={{ height: 500 }}>
        {MapComponent}
      </ExplorePanel>
      <ExploreSection title="Supplier Results">
        <Suppliers />
      </ExploreSection>
    </>
  );
}
