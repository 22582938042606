import { Col, Divider, Row, Typography } from 'antd';
import React, { useState } from 'react';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import ExploreSection from 'src/components/project/explore/ExploreSection';
import MarketPriceByFormLineChart from 'src/components/analytics/MarketPriceByFormLineChart';
import PeriodSelect from 'src/components/form/PeriodSelect';
import { PreferredUnits } from 'src/utils/units';
import SupplierCapabilityBarChart from 'src/components/project/explore/charts/SupplierCapabilityBarChart';
import ExplorePanel from 'src/components/project/explore/ExplorePanel';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';

const EMPTY_OPTIONS = {};

export default function Pricing() {
  const [months, setMonths] = useState(6);
  const filters = useRelevantFiltersForForm() || EMPTY_OPTIONS;
  const [projectSettings] = useConciergeContextState([
    'explore',
    'projectSettings'
  ]);

  return (
    <>
      <ExploreSection className="pricing-section">
        <Row gutter={16}>
          <Col span={24}>
            <Typography.Paragraph strong className="font-size-4 mb-lmd">
              Supply
            </Typography.Paragraph>
            <SupplierCapabilityBarChart />
          </Col>
        </Row>
      </ExploreSection>
      <Divider className="my-xl" />
      <ExploreSection className="pricing-section">
        <Row gutter={16}>
          <Col span={24}>
            <Typography.Paragraph strong className="font-size-4 mb-lmd">
              Pricing History
            </Typography.Paragraph>
            <ExplorePanel className="price-history-chart__panel" pad>
              <PeriodSelect value={months} onChange={(v) => setMonths(v)} />
              <Typography.Paragraph style={{ margin: '24px 0 0 0' }}>
                Material Price in Region ($/
                <PreferredUnits type="weight" />)
              </Typography.Paragraph>

              <MarketPriceByFormLineChart
                months={months}
                filters={filters}
                priceSettings={projectSettings?.prices}
              />
            </ExplorePanel>
          </Col>
        </Row>
      </ExploreSection>
    </>
  );
}
