import {
  EyeOutlined,
  MoreOutlined,
  SafetyCertificateFilled,
  SearchOutlined
} from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Col,
  Dropdown,
  Empty,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
  Tooltip,
  Typography
} from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import useGetWarehouseTdss from 'src/Query/useGetWarehouseTdss';
import TdsQuantity from 'src/components/project/search/TdsQuantity';
import { sortableColumnTitle } from 'src/pages/concierge/ProposalBuilder/columns/usePbColumns';
import { currencyValuePrice } from 'src/utils/currency';
import { useManageTdsModal } from 'src/pages/supplier/useManageTds';
import LogPricingModal from 'src/components/supplier/LogPricingModal';
import useIsCompanyMember from 'src/hooks/useIsCompanyMember';
import { deleteTds } from 'src/Mutation';
import emptyTable from 'src/images/emptyTable.png';
import useIsConcierge from 'src/hooks/useIsConcierge';
import { locationRender } from 'src/pages/rfq/proposal/supply/SupplyRoute';

export default function SupplierTdss() {
  const { supplierId } = useParams();

  const isCompanyMember = useIsCompanyMember(supplierId);
  const isConcierge = useIsConcierge();

  const [search, setSearch] = useState();
  const [orderBy, setOrderBy] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    page_size: 10
  });

  const { data, isLoading } = useGetWarehouseTdss({
    company_or_distributor_id__in: [supplierId],
    status__not_in: 'closed',
    properties: { certs: [] },
    search,
    is_capability: false,
    source__in:
      isCompanyMember && !isConcierge ? ['supplier_direct'] : undefined,
    fields: [
      'sku',
      'tds_id',
      'type',
      'type_code',
      'form',
      'form_code',
      'location_id',
      'city',
      'state',
      'country',
      'capacity_lbs',
      'supply_capacity_lbs'
    ],
    agg_fields: {
      document: ['url'],
      location_pricelog: [
        'date',
        'created_at',
        'price',
        'quantity',
        'units',
        'currency'
      ]
    },
    ...pagination,
    ...(orderBy ? { order_by: orderBy } : {})
  });

  const [tdsBeingEdited, setTdsBeingEdited] = useState();
  const { ManageTdsModal, openFunc: openManageTdsModal } = useManageTdsModal({
    additionalTdsParams: {
      send_email: true
    },
    tdsId: tdsBeingEdited
  });

  const queryClient = useQueryClient();
  const { mutate: deleteTdsMutation, isLoading: deletingTds } = useMutation({
    mutationFn: deleteTds,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['technical-data-sheet']
      });
      queryClient.invalidateQueries({
        queryKey: ['warehouse', 'technical-data-sheet']
      });
      queryClient.invalidateQueries({
        queryKey: ['price-log', { tds__company: supplierId }]
      });
    }
  });

  return (
    <>
      {ManageTdsModal}
      <Row
        justify="space-between"
        className="supplier-profile--materials-table--controls"
      >
        <Col>
          <Input
            size="small"
            placeholder="Search material"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            suffix={<SearchOutlined />}
          />
        </Col>
        <Col>
          <Space direction="horizontal" size={8}></Space>
        </Col>
      </Row>
      <Table
        className="supplier-profile--materials-table"
        loading={isLoading || deletingTds}
        rowKey="id"
        columns={[
          {
            title: sortableColumnTitle('SKU', 'sku'),
            sorter: true,
            dataIndex: 'sku',
            render: (sku, tds) => (
              <>
                <span>{sku || tds?.tds_id?.slice(0, 8) || '(unknown)'}</span>
                <span className="subcontent">{locationRender(tds)}</span>
              </>
            )
          },
          {
            title: sortableColumnTitle('Material', 'type_code'),
            sorter: true,
            dataIndex: 'type',
            render: (typeCode) => typeCode || '(...processing TDS)'
          },
          {
            title: 'Certs',
            dataIndex: 'property_certs_value',
            key: 'property-certs',
            render: (values) => {
              const filteredValues = values.filter((val) => !!val);
              return (
                <Tooltip
                  title={
                    filteredValues?.length ? (
                      <ul style={{ margin: 0, paddingLeft: 12 }}>
                        {filteredValues.map((singleCert) => (
                          <li key={singleCert}>{singleCert}</li>
                        ))}
                      </ul>
                    ) : null
                  }
                >
                  <Typography.Text
                    type={filteredValues?.length ? 'primary' : 'secondary'}
                  >
                    <SafetyCertificateFilled /> {filteredValues?.length || '0'}
                  </Typography.Text>
                </Tooltip>
              );
            }
          },
          {
            title: sortableColumnTitle('Form', 'form_code'),
            sorter: true,
            dataIndex: 'form'
          },
          {
            title: 'Cap. (lbs)',
            sorter: true,
            dataIndex: 'capacity_lbs',
            render: (_, tdsObj) => <TdsQuantity tds={tdsObj} />
          },
          {
            title: 'Current Avg. Price',
            dataIndex: 'location_pricelog',
            render: (priceLogs) => {
              const latestPrice = priceLogs
                ?.filter(({ price }) => !!price)
                .find(() => true);
              return (
                <>
                  {latestPrice
                    ? currencyValuePrice(
                        latestPrice.price,
                        latestPrice.currency,
                        latestPrice.units
                      )
                    : '--'}{' '}
                  <span className="subcontent">
                    {latestPrice
                      ? `Last updated ${dayjs(latestPrice.date).format(
                          'MM/DD/YYYY'
                        )}`
                      : 'No pricing data'}
                  </span>
                </>
              );
            }
          },
          {
            title: '',
            dataIndex: 'document',
            fixed: 'right',
            render: (documents, { tds_id, location_id }) => {
              const validDocs = documents?.filter((document) => !!document.url);
              return (
                <Space direction="horizontal" size={8}>
                  {isCompanyMember ? (
                    <LogPricingModal tdsLocationId={location_id} />
                  ) : null}
                  {validDocs?.length ? (
                    <a
                      title="View TDS Document"
                      target="_blank"
                      href={`https://django-image-upload.s3.amazonaws.com/${validDocs[0].url}`}
                    >
                      <Button size="small" htmlType="button">
                        <EyeOutlined />
                      </Button>
                    </a>
                  ) : null}
                  {isCompanyMember ? (
                    <Dropdown
                      trigger="click"
                      menu={{
                        items: [
                          {
                            key: 'edit',
                            label: (
                              <button
                                className="bare"
                                type="button"
                                onClick={() => {
                                  setTdsBeingEdited(tds_id);
                                  openManageTdsModal();
                                }}
                              >
                                Manage
                              </button>
                            )
                          },
                          {
                            key: 'remove',
                            label: (
                              <Popconfirm
                                title="Remove TDS"
                                description="Are you sure you want to remove this TDS from your profile?"
                                onConfirm={() => deleteTdsMutation(tds_id)}
                                okText="Remove TDS"
                                okButtonProps={{ danger: true }}
                                cancelText="Cancel"
                              >
                                <button className="bare" type="button">
                                  Remove
                                </button>
                              </Popconfirm>
                            ),
                            danger: true
                          }
                        ]
                      }}
                    >
                      <MoreOutlined />
                    </Dropdown>
                  ) : null}
                </Space>
              );
            }
          }
        ]}
        dataSource={data?.results}
        pagination={{
          current: pagination.page,
          pageSize: pagination.page_size,
          total: data?.count,
          onChange: (page, page_size) => setPagination({ page, page_size }),
          showSizeChanger: true,
          pageSizeOptions: [10, 25],
          showTotal: (total, [from, to]) =>
            `Displaying items ${from} - ${to} of ${data?.count} total`
        }}
        onChange={(_pagination, _filters, sorter) => {
          if (
            !['ascend', 'descend'].includes(sorter?.order) ||
            (!sorter.column.sortKey &&
              typeof sorter.column.dataIndex !== 'string')
          ) {
            setOrderBy(null);
          } else {
            setOrderBy(
              `${sorter.order === 'descend' ? '-' : ''}${
                sorter.column.sortKey || sorter.column.dataIndex
              }`
            );
          }
        }}
        rowSelection={false}
        locale={{
          emptyText: (
            <Empty
              className="material-table-empty"
              image={emptyTable}
              description=""
            >
              <Typography.Title level={4}>No Material Data</Typography.Title>
              <Typography.Paragraph>
                Add material data to appear in search and compare pricing with
                other suppliers.
              </Typography.Paragraph>
            </Empty>
          )
        }}
      />
    </>
  );
}
